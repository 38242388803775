var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"inspection-list"},[_c('div',{staticClass:"inspection-list-header"},[(_vm.canStartInspection)?_c('div',{staticClass:"start-inspection"},[_c('button',{on:{"click":function($event){_vm.showStartModal = true}}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.START_INSPECTION"))+" ")])]):_vm._e(),(_vm.canTakeOverInspection)?_c('div',{staticClass:"start-inspection"},[_c('button',{on:{"click":function($event){_vm.showTakeOverModal = true}}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.TAKE_OVER_INSPECTION"))+" ")])]):_vm._e(),(_vm.canEditInspection)?_c('inspection-view-inspection-edit-inspection',{attrs:{"inspection-data":_vm.inspection},on:{"onViewInspection":_vm.onViewInspection}}):_vm._e()],1),(
        [_vm.INSPECTION_STATUS_IN_PROGRESS, _vm.INSPECTION_STATUS_COMPLETED].includes(
          _vm.inspection.status
        )
      )?_c('div',{staticClass:"inspection-list-content"},_vm._l((_vm.inspection.inspectionForm.inspectionFormSections.filter(
          (item) => _vm.canShowElement(item, _vm.inspectionFieldsModel)
        )),function(section){return _c('div',{key:section.id,staticClass:"inspection-list-content-item",on:{"click":function($event){return _vm.openInspectionSection(section)}}},[_c('div',{staticClass:"inspection-list-content-item-left"},[_c('div',{staticClass:"inspection-list-content-item-left-image"},[_c('i',{staticClass:"fal fa-camera"}),(section.icon)?_c('img',{attrs:{"src":section.icon,"alt":section.name}}):_vm._e()]),_c('h4',[_vm._v(_vm._s(section.name))])]),_c('div',{staticClass:"inspection-list-content-item-right"},[_c('ul',[(_vm.isSectionFillComplete(section, _vm.inspectionFieldsModel))?_c('li',{staticClass:"percent"},[_vm._v(" "+_vm._s(_vm.getSectionScore(section))+"% ")]):_vm._e(),(
                _vm.isSectionTouched(section, _vm.inspectionFieldsModel) &&
                !_vm.isSectionFillComplete(section, _vm.inspectionFieldsModel)
              )?_c('li',[_c('i',{staticClass:"far fa-times-circle",staticStyle:{"color":"red"}})]):_vm._e(),(_vm.isSectionFillComplete(section, _vm.inspectionFieldsModel))?_c('li',[_c('i',{staticClass:"far fa-check-circle"})]):_vm._e(),(
                [
                  _vm.INSPECTION_STATUS_IN_PROGRESS,
                  _vm.INSPECTION_STATUS_COMPLETED,
                ].includes(_vm.inspection?.status)
              )?_c('li',[_c('base-button',{staticClass:"details",attrs:{"icon":"","size":"sm"}},[_c('i',{staticClass:"far fa-arrow-right"})])],1):_vm._e()])])])}),0):_vm._e(),_c('div',{staticClass:"inspection-list-footer"},[(_vm.canGenerateReport)?_c('base-button',{staticClass:"report",attrs:{"icon":"","size":"sm"},on:{"click":_vm.downloadReport}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.GENERATE_REPORT"))+" "),_c('img',{attrs:{"src":"/img/sm-file-pdf-outline.svg","alt":"icon"}})]):_vm._e(),(_vm.canCompleteInspection)?_c('base-button',{staticClass:"done",attrs:{"icon":"","size":"sm"},on:{"click":_vm.startCompleteInspection}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.COMPLETE_INSPECTION"))+" "),_c('img',{attrs:{"src":"/img/sm-check.svg","alt":"icon"}})]):_vm._e()],1)]),(_vm.inspectionSectionModalOpened)?_c('div',{staticClass:"resizable-wrapper",class:[_vm.inspectionSectionModalOpened ? 'show' : 'hide']},[_c('inspection-view-inspection-section-modal',{attrs:{"loading":_vm.loading,"sectionForm":_vm.openedSection,"sectionData":_vm.inspectionFieldsModel[_vm.openedSection.id],"inspection":_vm.inspection,"isLastSection":_vm.isLastSection,"isFirstSection":_vm.isFirstSection,"disabled":!_vm.canDoInspection,"inspectionData":_vm.inspectionFieldsModel},on:{"onCloseModal":_vm.closeInspectionSection,"onChangeSection":_vm.openInspectionSection,"onNextSection":_vm.openNextInspectionSection,"onPrevSection":_vm.openPrevInspectionSection,"onInspectionUpdated":_vm.openNextInspectionSection,"sectionChanged":(value) => {
          _vm.sectionChanged(_vm.openedSection.id, value);
        }}})],1):_vm._e(),(_vm.showUntouchedFieldModal)?_c('inspection-view-inspection-untouched-fields',{attrs:{"inspection":_vm.inspection,"inspectionFieldsModel":_vm.inspectionFieldsModel},on:{"onCloseModal":() => {
        _vm.showUntouchedFieldModal = false;
      },"onOpenSection":(section) => {
        _vm.openInspectionSection(section);
        _vm.showUntouchedFieldModal = false;
      }}}):_vm._e(),(_vm.showRemindersModal)?_c('inspection-view-inspection-reminders',{attrs:{"inspection":_vm.inspection},on:{"onCloseModal":() => {
        _vm.showRemindersModal = false;
      },"onContinueStep":(section) => {
        _vm.showRemindersModal = false;
        _vm.remindersValidated = true;
        _vm.showCommentAndSignModal = true;
      }}}):_vm._e(),(_vm.showCommentAndSignModal)?_c('inspection-view-inspection-comment-and-sign',{attrs:{"inspection":_vm.inspection},on:{"onCloseModal":() => {
        _vm.remindersValidated = false;
        _vm.showCommentAndSignModal = false;
      },"onSignAndSend":_vm.completeInspection}}):_vm._e(),_c('modal',{attrs:{"show":_vm.showCompleteModal,"modal-classes":"modal-secondary estimation"},on:{"update:show":function($event){_vm.showCompleteModal=$event}}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/check-green.svg","alt":"icon"}})]),_c('p',[_vm._v(_vm._s(_vm.$t("INSPECTIONS.COMPLETE_INFORMATIONS_SENT")))]),_c('template',{slot:"footer"},[_c('Button',{staticClass:"no",on:{"click":function($event){_vm.showCompleteModal = false}}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.COMPLETE"))+" ")]),_c('Button',{staticClass:"no",on:{"click":_vm.downloadReport}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.GENERATE_REPORT"))+" ")]),(_vm.canViewEstimate)?_c('Button',{staticClass:"yes",on:{"click":_vm.viewEstimate}},[_vm._v(" "+_vm._s(_vm.$t("ESTIMATES.SHOW_ESTIMATE"))+" ")]):_vm._e()],1)],2),_c('inspection-confirmation-modal',{attrs:{"confirm-action":_vm.startInspection,"confirm-button-text":_vm.$t('COMMON.YES_START'),"loading":_vm.loading,"message":_vm.$t('INSPECTIONS.START_THIS_INSPECTION'),"open":_vm.showStartModal,"modal-classes":"modal-secondary start small"},on:{"update:open":function($event){_vm.showStartModal=$event}}}),_c('inspection-confirmation-modal',{attrs:{"confirm-action":_vm.takeOverInspection,"confirm-button-text":_vm.$t('INSPECTIONS.YES_TAKE_OVER'),"loading":_vm.loading,"message":_vm.$t('INSPECTIONS.TAKE_OVER_THIS_INSPECTION', {
        name: _vm.$objectDenomination(_vm.inspection.startedBy),
      }),"open":_vm.showTakeOverModal,"modal-classes":"modal-secondary take-over start"},on:{"update:open":function($event){_vm.showTakeOverModal=$event}}}),_c('inspection-confirmation-modal',{attrs:{"confirm-action":() => (_vm.showAlertModal = false),"confirm-button-text":_vm.$t('COMMON.OK'),"loading":_vm.loading,"message":_vm.$t('INSPECTIONS.IN_PROGRESS_WARNING'),"open":_vm.showAlertModal,"show-cancel-button":false,"modal-classes":"modal-secondary take-over start"},on:{"update:open":function($event){_vm.showAlertModal=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }