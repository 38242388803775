<template>
  <div class="elite-tabs-wrapper-content">
    <div class="global-header">
      <h3>{{ inspection.code }} - {{ $t("COMMON.ESTIMATION") }}</h3>
      <div class="buttons">
        <button class="edit" @click="showEstimation" v-if="canShowEstimation">
          <span> {{ $t("INSPECTIONS.SHOW_ESTIMATION") }} </span>
        </button>
      </div>
    </div>
    <estimate-view-global
      class-name=""
      :estimate="inspection.estimation"
      :view-header="false"
      show-code
    />
  </div>
</template>

<script>
import EstimateViewGlobal from "../../EstimateManagement/partials/EstimateViewGlobal.vue";

export default {
  name: "inspection-view-estimate",

  components: { EstimateViewGlobal },

  props: ["inspection"],

  data() {
    return {};
  },

  computed: {
    canShowEstimation: function () {
      return (
        !!this.inspection.estimation &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_MODULE_ESTIMATIONS) &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_ESTIMATIONS)
      );
    },
  },

  created() {},

  methods: {
    showEstimation() {
      this.$router.push(this.$objectViewRoute(this.inspection.estimation));
    },
  },

  mounted() {},

  watch: {},
};
</script>
